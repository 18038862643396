import { useEffect, useRef, useMemo } from 'react'

import { drawImg, getImg } from '@common/func'

import { Canvas, Container } from './style'

interface IFlipbook {
	progress: number
	id: string
	size?: number
}

const Flipbook = (p: IFlipbook) => {
	const widthSize = () => window.innerWidth

	const wSize = widthSize() >= 701 ? 1920 : 732
	const hSize = widthSize() >= 701 ? 1080 : 412

	const { id, progress, size } = p

	const canvas = useRef<HTMLCanvasElement>(null)

	const img = useMemo(() => getImg(progress, id), [progress, id])

	useEffect(() => {
		drawImg(canvas.current, img)
	}, [img])

	return (
		<Container>
			<Canvas size={size || 1} width={wSize} height={hSize} ref={canvas} />
		</Container>
	)
}

export default Flipbook
