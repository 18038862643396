import styled from '@emotion/styled'

export interface IPin {
	progress: number
	setProgress: (progress: number) => void
	height: string
	children: JSX.Element | JSX.Element[]
}

export interface IContainer {
	height: string
}

export const Container = styled.div<IContainer>`
	position: relative;
	height: ${p => p.height};
	width: 100%;
`

export interface IView {
	//
}

export const View = styled.div<IView>`
	position: sticky;
	top: 0;

	height: 100vh;
	/* width: 100vw; */

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
