import React from 'react'
import HeadingTextSection from '../../../components/HeadingTextSection/Index'

const Index = ({ progress, language }) => {
	const contentArray = [
		{
			key: 4,
			no: 3,
			head: language === 'English' ? `Call Translation` : `ترجمة المكالمات`,
			para: (
				<span className='gray-class'>
					{language === 'English'
						? `Keep your communications clear and concise, especially when you're speaking on the phone. With call translation, you can easily`
						: `اجعل اتصالاتك واضحة وموجزة،عندما تتحدث على الهاتف، فمع ترجمة المكالمات،`}{' '}
					<span className='green-class'>
						{language === 'English'
							? `translate phone calls in real time without any lag time`
							: `يمكنك بسهولة فهم مختلف لغات المتح ّدث بشكل فور ّي ومباشر دون أ ّي تاخير.`}
					</span>
				</span>
			),
			dura: 1200,
			side: true,
		},

		{
			key: 5,
			no: 3,
			head: language === 'English' ? `Image Translation` : `ترجمة الصور`,
			para: (
				<span className='gray-class'>
					{language === 'English'
						? `When you're out and about, you'll encounter many things that you might not be able to read or understand. With`
						: `عندما تكون بالخارج، ستواجه العديد من الأمور التي قد لا تتمكن من قراءتها أو فهمها. باستخدام`}{' '}
					<span className='green-class'>
						{language === 'English' ? `image translation,` : `ميزة ترجمة الصور،`}{' '}
					</span>{' '}
					<span className='lightgray-class'>
						{language === 'English'
							? `simply take a photo of what you need to be translated,`
							: `ما عليك سوى التقاط صورة لما تحتاج إلى ترجمته،`}
					</span>{' '}
					{language === 'English'
						? `and our earbuds will provide an accurate translation.`
						: `وستوفر سماعات الأذن الخاصة بنا ترجمة دقيقة من أجلك.`}
				</span>
			),
			dura: 1600,
			side: true,
		},
		{
			key: 6,
			no: 3,
			head: language === 'English' ? `Smart Assistant` : `مساعد الذكي`,
			para: (
				<span className='gray-class'>
					{language === 'English'
						? `A good earbud should be easy to use and manage.`
						: `نؤمن بأن سماعة الأذن الجيدة يجب أن تكون سهلة الاستخدام والإدارة.`}
					manage.{' '}
					<span className='green-class'>
						{language === 'English'
							? `Our smart assistant feature lets you easily control your earbuds with voice commands.`
							: ` ومنه تتيح لك ميزة المساعد الذكي لدينا التحكم بسهولة في سماعات الأذن باستخدام الأوامر الصوتية.`}
					</span>{' '}
					{language === 'English'
						? `The smart assistant is also there to help manage your translations and earbuds so that you can focus on enjoying your conversations.`
						: `المساعد الذكي موجود أي ًضا للمساعدة في إدارة الترجمات وسماعات الأذن بحيث يمكنك التركيز على الاستمتاع بمحادثاتك.`}
				</span>
			),
			dura: 1600,
			side: false,
		},
	]
	return (
		<React.Fragment>
			{/* eslint-disable-next-line array-callback-return */}
			{contentArray.map(({ key, head, para, dura, side }) => {
				var value1 = 0.1666666
				var value2 = 0.1666666

				if (progress <= value1 * key && progress >= value1 * key - value2) {
					return (
						<HeadingTextSection
							language={language}
							classStyleName={`dummy3Right`}
							key={key}
							h1Heading={head}
							h5Heading={para}
						/>
					)
				}
			})}
		</React.Fragment>
	)
}

export default Index
