import styled from '@emotion/styled'

export const Container = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	/* padding: 10% 0px; */

	align-items: center;
	justify-content: center;
	/* z-index: 1; */
`

interface ICanvas {
	size: number
}

export const Canvas = styled.canvas<ICanvas>`
	height: auto;
	width: ${p => 100 * p.size}%;
	aspect-ratio: 1.7777777777;
	/* margin: 50% 0px; */
	/* z-index: 0; */
`
