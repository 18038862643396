import { MutableRefObject, useEffect, useState } from 'react'

type UseOnScreen = <T extends Element>(
	ref: MutableRefObject<T | null>,
	rootMargin?: string,
	threshold?: number
) => boolean

export const useOnScreen: UseOnScreen = (ref, rootMargin = '0px', threshold = 0) => {
	const [isIntersecting, setIntersecting] = useState<boolean>(false)

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => setIntersecting(entry.isIntersecting),
			{ rootMargin, threshold }
		)

		const el = ref.current

		if (!el) return

		observer.observe(el)

		return () => observer.unobserve(el)
	}, [ref, rootMargin, threshold])

	return isIntersecting
}
