import metaFrames from '@assets/meta/frames'
import { getImgEl } from '@utils/imageLoader'

export const getImg = (progress: number, id: string) => {
	const { ceil } = Math

	const total = metaFrames[id]
	const n = ceil(total * progress).toLocaleString('en-US', { minimumIntegerDigits: 3 })

	return `${id}_${n === '000' ? '001' : n}.jpg`
}

type DrawImg = (canvas: HTMLCanvasElement | null, img: string) => void

export const drawImg: DrawImg = async (canvas, img) => {
	if (!canvas) return

	const el = await getImgEl(img)
	if (!el) return

	const ctx = canvas.getContext('2d')
	if (!ctx) throw Error('ctx is null')

	ctx.clearRect(0, 0, canvas.width, canvas.height)
	ctx.drawImage(el, 0, 0)
}

export const calPinHeight = (id: string, threshold: number = 1) => {
	const total = metaFrames[id]
	const n = total * 10.7 * threshold // 10.7 is a research derived constant
	return `${n}px`
}
