import styled from '@emotion/styled'

export const Container = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	color: white;
	background-color: black;
`
