import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Zero from '../../../assets/A3-G1T/0.png'
import One from '../../../assets/A3-G1T/1.png'
import Three from '../../../assets/A3-G1T/3.png'
import Four from '../../../assets/A3-G1T/4.png'
import Five from '../../../assets/A3-G1T/5.png'
import Six from '../../../assets/A3-G1T/6.png'
import Seven from '../../../assets/A3-G1T/7.png'
import Eight from '../../../assets/A3-G1T/8.png'
import Nine from '../../../assets/A3-G1T/9.png'
import Ten from '../../../assets/A3-G1T/10.png'
import Eleven from '../../../assets/A3-G1T/11.png'
import Twelve from '../../../assets/A3-G1T/12.png'
import Thirteen from '../../../assets/A3-G1T/13.png'
import Fourteen from '../../../assets/A3-G1T/14.png'
import Fifteen from '../../../assets/A3-G1T/15.png'
import Sixteen from '../../../assets/A3-G1T/16.png'
import Seventeen from '../../../assets/A3-G1T/17.png'
import Eighteen from '../../../assets/A3-G1T/18.png'
import Nineteen from '../../../assets/A3-G1T/19.png'
import Twenty from '../../../assets/A3-G1T/20.png'
import TwentyOne from '../../../assets/A3-G1T/21.png'
import TwentyTwo from '../../../assets/A3-G1T/22.png'

interface Props {
	language: string
}

const Section10: React.FC<Props> = ({ language }) => {
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	}
	const images = [
		Zero,
		One,
		Three,
		Four,
		Five,
		Six,
		Seven,
		Eight,
		Nine,
		Ten,
		Eleven,
		Twelve,
		Thirteen,
		Fourteen,
		Fifteen,
		Sixteen,
		Seventeen,
		Eighteen,
		Nineteen,
		Twenty,
		TwentyOne,
		TwentyTwo,
	]

	return (
		<div
			style={{
				marginTop: '0 !important',
			}}
			className='contentSection-container'
		>
			<h1
				style={{
					color: 'white',
				}}
			>
				Our Products
			</h1>
			<Carousel
				swipeable={true}
				draggable={false}
				showDots={false}
				responsive={responsive}
				ssr={true} // means to render carousel on server-side.
				infinite={false}
				keyBoardControl={true}
				containerClass='carousel-container'
				// dotListClass="custom-dot-list-style"
				// itemClass="customItemClass"
				arrows={true}
			>
				{images.map((image, index) => (
					<img
						key={index}
						src={image}
						className='carousel-image'
						alt=''
						style={{
							width: '100%',
							height: 'auto',
							objectFit: 'contain',
						}}
					/>
				))}
			</Carousel>
		</div>
	)
}

export default Section10
