import styled from '@emotion/styled'
interface IDummy {
	val?: number
	children: JSX.Element | JSX.Element[]
}

const View = styled.div<IDummy>`
	position: absolute;
	left: 50%;
	top: 65%;
	transform: translate(-50%, -50%);
	width: max-content;
	font-size: 3.2vw;
`

const Dummy = (p: IDummy) => {
	const { val, children } = p

	return <View val={val}>{children}</View>
}

export default Dummy
