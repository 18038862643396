interface IFrames {
	[index: string]: number
}

// const width = () => window.innerWidth

const frames: IFrames = {
	A: 36, // 69
	B: 55, // 173
	C: 32, // 91
}

// const frames: IFrames =
// 	width() >= 701
// 		? {
// 				A: 29,
// 				B: 33,
// 				C: 37,
// 		  }
// 		: {
// 				a: 29,mobFrames2
// 				b: 33,
// 				c: 37,
// 		  }

export default frames
