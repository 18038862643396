import React from 'react'
import HeadingTextSection from '../../../components/HeadingTextSection/Index'

const Index = ({ progress, language }) => {
	const contentArray = [
		{
			key: 1,
			no: 2,
			head: language === 'English' ? `Instant Translation` : `ترجمة فورية`,
			para: (
				<span className='gray-class'>
					{language === 'English'
						? `If you're traveling to a country where English is not the primary language, you may face difficulties communicating with locals.`
						: `إذا كنت مساف ًرا إلى بلد ليست اللغة الإنجليزية هي لغته الأساسية، فقد تواجه صعوبات في التواصل مع السكان المحليين.`}{' '}
					<span className='green-class'>
						{language === 'English'
							? `Build rapport and deepen your connections with real-time translation of up to
						35 languages.`
							: `من خلال هذه السماعة؛ قم بالتواصل بشكل فعال ومباشر من خلال الترجمة التلقائية والفورية لمايصل إلى 35 لغة.`}
					</span>{' '}
					{language === 'English'
						? `Now you can have live conversations with anyone, anywhere in the world.`
						: `من الآن يمكنك إجراء محادثات مباشرة مع أي شخص في أي مكان في العالم!`}
				</span>
			),
			dura: 800,
			side: true,
		},
		{
			key: 2,
			no: 2,
			head:
				language === 'English' ? `Offline Translation` : `الترجمة دون اتصال بالإنترنت`,
			para: (
				<span className='gray-class'>
					{language === 'English'
						? `A dependable internet connection is key when you're traveling, but it's not always guaranteed. That's why`
						: `يعد الاتصال بالإنترنت الذي يمكن الاعتماد عليه أم ًرا أساسيا عند السفر، ولكن الحصول عليه ليس مضمونًا دائ ًما. لهذا`}{' '}
					<span className='green-class'>
						{language === 'English'
							? `our earbuds also offer offline translation when you're not connected to the internet.`
							: `السبب تقدم سماعات الأذن الخاصة بنا أي ًضا ترجمة موثوقة دون الحاجة اتصال بالإنترنت عندما لا تكون متصلا بالإنترنت.`}
					</span>{' '}
					{language === 'English'
						? `A reliable offline translation earbud is a must-have for any traveler.`
						: `الترجمة الموثوقة دون الحاجة لاتصال إنترنت هي أم ُر سيحتاحه كل مسافر بالتأكيد.`}
				</span>
			),
			dura: 800,
			side: false,
		},
		{
			key: 3,
			no: 2,
			head: language === 'English' ? `Voice Recognition` : `ميّزة التعرف على الصوت`,
			para: (
				<span className='gray-class'>
					<span className='lightgray-class'>
						{language === 'English' ? `99 earbuds` : ` 99 سماعة`}
					</span>{' '}
					<span className='green-class'>
						{language === 'English'
							? `use state-of-the-art voice recognition technology to provide accurate translations.`
							: `تستخدم أحدث تقنيات التع ّرف على الصوت لتقديم ترجمات دقيقة.`}
					</span>{' '}
					{language === 'English'
						? `This way, you can rest assured that you'll be able to understand and be understood while abroad.`
						: `بهذه الطريقة، يمكنك أن تطمئن إلى أنك ستكون قاد ًرا على الفهم وإيصال أفكارك أثناء تواجدك بالخارج.`}
				</span>
			),
			dura: 1200,
			side: false,
		},
	]
	return (
		<React.Fragment>
			{/* eslint-disable-next-line array-callback-return */}
			{contentArray.map(({ key, head, para, dura, side }) => {
				var value1 = 0.1666666
				var value2 = 0.1666666

				if (progress <= value1 * key && progress >= value1 * key - value2) {
					return (
						<HeadingTextSection
							language={language}
							classStyleName={`dummy3Left`}
							key={key}
							h1Heading={head}
							h5Heading={para}
						/>
					)
				}
			})}
		</React.Fragment>
	)
}

export default Index
