import React, { useEffect, useState } from 'react'
import img2 from '../../../assets/images/img2.mp4'
import HeadingTextSection from '../../HeadingTextSection/Index'

const Index = ({ language }) => {
	const contentArray = [
		{
			key: 1,
			head: `A higher level \t\t\t of Control`,
			para: (
				<span className='gray-class'>
					Touch control lets you easily manage playback functions from the stem.{' '}
					<span className='lightgray-class'>
						Swipe up or down to adjust volume, press to play and pause music or answer and
						end calls,
					</span>{' '}
					or hold to switch between{' '}
					<span className='green-class'>
						Active Noise Cancellation and Adaptive Transparency.
					</span>
				</span>
			),
		},
	]

	const [videoTimer, setVideoTimer] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setVideoTimer(true)
		}, 4000)
		return () => clearTimeout(timer)
	}, [])

	return (
		<section className='contentSection section8'>
			<div className='contentSection-container section8-container'>
				<div className='section8-img'>
					{videoTimer && (
						<video muted loop preload='none' autoPlay playsInline src={img2} alt='' />
					)}
					<h1 style={{ whiteSpace: 'pre-wrap' }} className='heading1'>
						{contentArray[0].head}
					</h1>
				</div>

				<HeadingTextSection
					language={language}
					key={contentArray[0].key}
					h5Heading={contentArray[0].para}
				/>
			</div>
		</section>
	)
}

export default Index
