import { configureStore } from '@reduxjs/toolkit'

import mainSlice from './slices/main'

export const store = configureStore({
	middleware: middleware =>
		middleware({
			serializableCheck: false,
		}),
	reducer: {
		main: mainSlice,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
