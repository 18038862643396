import React, { useEffect } from 'react'
import HeadingTextSection from '../../HeadingTextSection/Index'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'

gsap.registerPlugin(ScrollTrigger)

const Index = ({ language }) => {
	const contentArray = [
		{
			key: 1,
			head:
				language === 'English'
					? `Expanding Your World \n Through Smart Translation`
					: `العالم سيصبح أوسع لديك \n .من خلال ترجمتها الذكيّة`,
			para: (
				<span className='gray-class'>
					<span
						className='text1'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? `Your world is only limited by the language you can comprehend. Now you can
						expand your connections with`
							: `عالمنا ُمق ّيد فقط باللغة التي يمكنك فهمها، ولكن الآن لم يعد الآمر كذلك، حيث مع تلك السماعة يمكنك توسيع معارفك وثقافاتك من خلال وصولك إلى `}
					</span>{' '}
					<span className='lightgray-class'>
						99 {language === 'English' ? 'earbuds' : 'سماعة'}.
					</span>{' '}
					<span
						className='text1'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? `These earbuds provide`
							: ` حيث توفّر سماعات الأذن هذه`}
					</span>{' '}
					<span className='green-class'>
						{language === 'English'
							? 'real-time translation of up to 35 languages,'
							: 'ترجمة تلقائية لما يصل إلى 35 لغة،'}
					</span>{' '}
					<span
						className='text1'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? `allowing you to hold conversations with anyone, anywhere in the world, without any language barriers. Whether you're traveling for business or pleasure, you'll be able to communicate with ease and connect with the world like never before.`
							: `مما يسمح لك بإجراء محادثات مع أي شخص في أي مكان في العالم، ودون أي حواجز لغوية. فسوا ًء كنت مساف ًرا للعمل أو للسياحة، ستتمكن من التواصل بسهولة والتواصل مع عالمك كما لم تج ّرب من قبل.`}
					</span>
				</span>
			),
		},
		{
			key: 2,
			head: language === 'English' ? `Limitless Connection` : `اتصال دائم وغير محدود`,
			para: (
				<span className='gray-class text2Mian'>
					<span
						className='text2'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? 'In addition to live translation,'
							: 'بالإضافة إلى الترجمة الح ّية،'}
					</span>{' '}
					<span className='lightgray-class'>
						99 {language === 'English' ? 'earbuds' : 'سماعة'}
					</span>{' '}
					<span
						className='text2'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English' ? 'also offers' : 'ترجمة أيضاً'}{' '}
					</span>
					<span className='green-class'>
						{language === 'English'
							? "offline translation for when you're not connected to the internet,"
							: 'دون الحاجة للاتصال بالإنترنت عندما لا تكون متصلاً،'}
					</span>{' '}
					<span
						className='text2'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? 'image translation for when you need help understanding street signs or menus, and a smart assistant to help manage your earbuds and translations. With'
							: 'بالإضافة إلى ترجمة الصور عندما تحتاج إلى مساعدة في فهم لافتات الشوارع أو القوائم، مع مساعد ذكي يتيح لك خيارات في التح ّكم بسماعات الأذن والترجمة النص ّية. الآن مع '}{' '}
					</span>{' '}
					<span className='lightgray-class'>
						99 {language === 'English' ? 'earbuds' : 'سماعة'}
					</span>{' '}
					<span
						className='text2'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? "you'll be able to connect with anyone anywhere."
							: 'ستتمكن من التواصل مع أي شخص في أي مكان'}
					</span>
				</span>
			),
		},
		{
			key: 3,
			head: language === 'English' ? `Futuristic Design` : 'تصميم عصري',
			para: (
				<span className='gray-class text3Mian'>
					<span className='lightgray-class'>
						{language === 'English' ? '99 earbuds' : 'سماعة 99'}
					</span>{' '}
					<span
						className='text3'
						style={{
							opacity: 0.75,
						}}
					>
						{' '}
						{language === 'English'
							? 'are functional and designed to look good and feel comfortable.'
							: 'هي سماعى أذن عمل ّية و ُمصممة لتبدو بأعلى درجات الأناقة والراحة'}
					</span>{' '}
					<span
						className='text3'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? 'They come in a sleek black color and an ergonomic design that fits snugly in your ear. With'
							: '.تأتي باللون الأسود الأنيق وبتصميم مريح يلائم أذنك بشكل ُمرضي'}{' '}
					</span>
					<span className='green-class'>
						{language === 'English'
							? 'up to 4 hours of battery life,'
							: 'مع بطارية بعمر طويل تتيح لك استخدام يصل إلى 4 ساعات،'}
					</span>{' '}
					<span
						className='text3'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? 'you can use them all day without having to worry about recharging.'
							: 'حيث يمكنك استخدامها طوال اليوم دون الحاجة إلى التفكير بضرورة شحنها أو توقّفها عن العمل.'}
					</span>{' '}
					<span
						className='text3'
						style={{
							opacity: 0.75,
						}}
					>
						{language === 'English'
							? "If you're looking for earbuds that will help you connect in style, look no further than"
							: ' فإذا كنت تبحث عن سماعات أذن تساعدك على الاتصال بالعالم من جديد وبتصميم مثال ّي؛ فابحث عن'}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? 'earbuds' : 'سماعة.'}
						</span>
					</span>
				</span>
			),
		},
	]

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.contentSection-container',
					start: 'top 100px',
					end: '+=300',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.text1', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.text1',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.text2Mian',
					start: 'top 300px',
					end: '+=200',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.text2', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.text2',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])
	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.text3Mian',
					start: 'top 300px',
					end: '+=300',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.text3', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.text3',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	return (
		<section className='contentSection'>
			<div className='contentSection-container'>
				{contentArray.map(({ key, head, para }) => (
					<HeadingTextSection
						language={language}
						key={key}
						h1Heading={head}
						h5Heading={para}
					/>
				))}
			</div>
		</section>
	)
}

export default Index
