import styled from '@emotion/styled'

interface IDummy {
	val?: number
	opacity: number
	no: number
	children: JSX.Element[] | JSX.Element
}

const View = styled.div<IDummy>`
	position: absolute;
	left: ${p => (p.no === 2 ? '20%' : '80%')};
	top: 50%;
	transform: translate(-50%, -50%);
	opacity: ${p => (p.opacity ? p.opacity : 1)};
	width: 250px;

	@media (max-width: 750px) {
		width: 350px;
		top: ${p => (p.no === 2 ? '25%' : '85%')};
		left: ${p => (p.no === 2 ? '30%' : '70%')};
		/* top: 75%;
		left: 50%; */
	}

	@media (max-width: 500px) {
		width: 300px;
		top: ${p => (p.no === 2 ? '20%' : '75%')};
		left: ${p => (p.no === 2 ? '50%' : '50%')};
		/* top: 75%;
		left: 50%; */
	}
`

const Dummy = (p: IDummy) => {
	const { val, no, children } = p
	var opa: number = 0

	return (
		<View no={no} opacity={opa} val={val}>
			{children}
		</View>
	)
}

export default Dummy
