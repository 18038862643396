import { useEffect, useState } from 'react'

import Routing from '@routes'
import { loadImages } from '@utils/imageLoader'

import Navbar from './components/Navbar/Index'
import Footer from './components/Footer/Index'

function App() {
	const [language, setLanguage] = useState('English')
	useEffect(() => {
		loadImages()
	}, [])

	return (
		<>
			<Navbar setLanguage={setLanguage} language={language} />
			<Routing language={language} />
			<Footer language={language} />
		</>
	)
}

export default App
