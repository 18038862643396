import { useOnScreen } from '@hooks/useOnScreen'
import { useCallback, useEffect, useRef } from 'react'
import { Container, IPin, View } from './styled'

const Pin = (p: IPin) => {
	const { children, height, setProgress } = p

	const contRef = useRef<HTMLDivElement>(null)
	const viewRef = useRef<HTMLDivElement>(null)
	const onScreen = useOnScreen(viewRef, undefined, 1)

	const heightN = Number(height.replace('px', ''))

	const onScroll = useCallback(() => {
		if (!contRef.current) return
		let scrollY = 0
		let disabled = false

		const offsetTop = contRef.current.offsetTop

		const onScroll = () => {
			scrollY = window.scrollY

			if (disabled) return

			window.requestAnimationFrame(() => {
				const progressRaw = (scrollY - offsetTop) / (heightN - window.innerHeight)
				const progressNormalized = progressRaw < 0 ? 0 : progressRaw > 1 ? 1 : progressRaw
				setProgress(progressNormalized)
				disabled = false
			})

			disabled = true
		}

		document.removeEventListener('scroll', onScroll)
		document.addEventListener('scroll', onScroll)

		// return () => {
		// 	document.removeEventListener('scroll', onScroll)
		// }
	}, [contRef, heightN, setProgress])

	useEffect(() => {
		if (!onScreen) return
		onScroll()

		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, [onScreen, onScroll])

	return (
		<Container ref={contRef} height={height}>
			<View ref={viewRef}>{children}</View>
		</Container>
	)
}

export default Pin
