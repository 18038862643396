// import { Fragment } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { RootState } from '@redux'
// import { setDummy } from '@redux/slices/main'

import styled from '@emotion/styled'

interface IDummy {
	val?: number
	children: JSX.Element | JSX.Element[]
	opacity: number
	no: number
	// index?: number
	// width?: string
	// height?: string
	// position?: string
	// left?: string
	// top?: Array<string>
	// translatex?: string | number
	// translatey?: string | number
}

const View = styled.div<IDummy>`
	position: absolute;
	left: ${p => (p.no === 2 ? '20%' : '80%')};
	top: 50%;
	transform: translate(-50%, -50%);
	opacity: ${p => p.opacity};
	width: 250px;

	@media (max-width: 750px) {
		width: 250px;
		top: ${p => (p.no === 2 ? '25%' : '85%')};
		left: ${p => (p.no === 2 ? '30%' : '70%')};
	}

	@media (max-width: 500px) {
		width: 300px;
		top: ${p => (p.no === 2 ? '20%' : '75%')};
		left: ${p => (p.no === 2 ? '50%' : '50%')};
	}
`

// width: 100vh;
// height: 100vh;
// display: flex;
// flex-direction: column;
// align-items: center;
// justify-content: center;
// position: ${p => (p.position ? p.position : 'absolute')};
// transform: ${p =>
// 	`translate(${p.translatex ? p.translatex : '-50%'}, ${
// 		p.translatey ? p.translatey : '-50%'
// 	})`} !important;
// top: ${p => p.top};
// left: ${p => p.left};
// z-index: ${p => p.index};
// width: ${p => p.width};

const Dummy = (p: IDummy) => {
	const {
		val,
		children,
		opacity,
		no,
		// position,
		// left,
		// top,
		// translatex,
		// translatey,
		// width,
		// height,
		// index,
	} = p

	// const dispatch = useDispatch()
	// const dummy = useSelector((state: RootState) => state.main.dummy)
	var opa: number = 0

	if (no >= 2 || no <= 3) {
		if (no === 2) {
			if (opacity < 0.25) opa = opacity * 4
			else if (opacity > 0.25 && opacity < 0.5) opa = 1 - (opacity - 0.5) * 6
		} else if (no === 3) {
			if (opacity > 0 && opacity < 0.5) opa = 0
			else if (opacity >= 0.5 && opacity < 0.85) opa = (opacity - 0.5) * 4
			else if (opacity >= 0.86 && opacity <= 1) opa = opacity / 1.15 - 1
		}
	}

	// else if (opacity > 0.25 || opacity < 0.5) opa = 1 - (opacity - 0.5) * 2

	return (
		<View
			no={no}
			opacity={opa}
			val={val}
			// position={position}
			// height={height}
			// left={left}
			// top={top}
			// translatex={translatex}
			// translatey={translatey}
			// width={width}
			// index={index}
		>
			{children}
			{/* <h1>Hello World!</h1> */}
			{/* {val !== undefined ? <p>{val.toFixed(3)}</p> : null} */}
			{/* <button onClick={() => dispatch(setDummy(Date.now().toString()))}>Click me!</button> */}
		</View>
	)
}

export default Dummy
