import React, { useEffect } from 'react'
// import img1 from '../../../assets/images/img1Mob.png'
import xs from '../../../assets/images/xs.webp'
import s from '../../../assets/images/s.webp'
import m from '../../../assets/images/m.webp'
import l from '../../../assets/images/l.webp'
import airpod from '../../../assets/images/airpod.webp'
import HeadingTextSection from '../../HeadingTextSection/Index'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const Index = ({ language }) => {
	const imageContainer = React.useRef(null)
	const imagexs = React.useRef(null)
	const images = React.useRef(null)
	const imagem = React.useRef(null)
	const imagel = React.useRef(null)
	const contentArray = [
		{
			key: 1,
			head: language === 'English' ? `Technical Specifications` : `المواصفات الفنية`,
			para: (
				<span className='TechnicalMain'>
					<span className='d1'>
						{language === 'English' ? `- earbud weight: 4 grams` : `وزن السماعة: 4 جرام`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud dimensions: L x W x H = 3.9 cm x 1.7 cm x 1.6 cm`
							: `أبعاد العرض × الارتفاع = 3.9 سم × 1.7 سم × 1.6 سم عمر بطارية سماعة الأذن:`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud battery life: up to 4 hours`
							: `ما يصل إلى 4 ساعات وقت شحن سماعة الأذن:`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud charging time: 1 hour`
							: `ساعة واحدة مدى السماعة:`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud wireless range: 10 meters`
							: ` 10 أمتار لغات ترجمة سماعات الأذن`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud translation languages: up to 35`
							: `حتى 35 عدد الأشخاص المتاحين للترجمة آثناء المكالمة:`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud call translation: up to 2 people`
							: `ما يصل إلى شخصين`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud image translation: objects and text`
							: `ترجمة الصور المتاحة: مختلف النصوص والإعلانات لغات المساعد الذكي:`}
					</span>
					<br />
					<span className='d1'>
						{language === 'English'
							? `- earbud smart assistant: voice commands in English, Turkish, German, and French`
							: `أوامر باللغات الإنجليزية والتركية والألمانية والفرنسية`}
					</span>
				</span>
			),
		},
	]

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.TechnicalMain',
					start: 'top center',
					end: '+=400',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d1', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d1',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: imageContainer.current,
					start: 'top center',
					end: ' bottom',
					pinSpacing: true,
					pin: true,
					scrub: 2,
				},
			})
			.to(imagel.current, {
				opacity: 1,
				ease: 'none',
				duration: 5,
			})
			.to(imagem.current, {
				opacity: 1,
				ease: 'none',
				duration: 5,
			})
			.to(images.current, {
				opacity: 1,
				ease: 'none',
				duration: 5,
			})
			.to(imagexs.current, {
				opacity: 1,
				ease: 'none',
				duration: 5,
			})
	}, [])

	return (
		<section
			className='contentSection'
			style={{
				flexDirection: 'column',
			}}
		>
			<div className='contentSection-container'>
				<HeadingTextSection
					language={language}
					key={contentArray[0].key}
					h1Heading={contentArray[0].head}
					h5Heading={contentArray[0].para}
				/>
			</div>
			<div ref={imageContainer} style={{ marginBottom: '50px' }}>
				<div
					style={{
						padding: '20px',
						background: 'transparent',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'flex-start',
						gap: '10px',
					}}
				>
					<img
						src={xs}
						ref={imagexs}
						alt=''
						style={{
							opacity: 0,
							width: '7.4%',
							// height: 'auto',
						}}
					/>
					<img
						src={s}
						ref={images}
						alt=''
						style={{
							opacity: 0,
							width: '8.1%',
						}}
					/>
					<img
						src={m}
						ref={imagem}
						alt=''
						style={{
							opacity: 0,
							width: '9.3%',
						}}
					/>
					<img
						src={l}
						ref={imagel}
						alt=''
						style={{
							opacity: 0,
							width: '10%',
						}}
					/>
					<img
						src={airpod}
						alt=''
						style={{
							width: '20%',
						}}
					/>
				</div>
			</div>
		</section>
	)
}

export default Index
