import React, { useState } from 'react'
import HeadingTextSection from '../../components/HeadingTextSection/Index'
import './style.css'

const Index = ({ language }) => {
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [emailAddress, setEmailAddress] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')

	const [displayError, setDisplayError] = useState(false)

	// eslint-disable-next-line no-unused-vars
	const submitHandler = e => {
		e.preventDefault()

		if (
			firstname === '' ||
			lastname === '' ||
			emailAddress === '' ||
			phoneNumber === ''
		) {
			setDisplayError(true)
		}
	}

	const contentArray = [
		{
			key: 1,
			head: language === 'English' ? `Contact Us` : `تواصل معنا`,
			para:
				language === 'English'
					? `"If you have any questions about your earbuds or the app, our customer service team will be more than happy to help.You can reach us by phone, email, live chat or fill out the form below, and we'll get back to you as soon as possible."`
					: `إذا كانت لديك أي أسئلة حول سماعات الأذن أو التطبيق، فسيكون فريق خدمة العملاء لدينا أكثر من سعداء لمساعدتك. يمكنك التواصل معنا عبر الهاتف أو البريد الإلكتروني أو الدردشة المباشرة أو ملء النموذج أدناه وسنعاود الاتصال بك في أقرب وقت ممكن.`,
		},
	]

	return (
		<footer className='footer' id={'contactus'}>
			<div className='footer-container'>
				<div className='footer-containt'>
					<HeadingTextSection
						language={language}
						classStyleName={'footerHead_text'}
						key={contentArray[0].key}
						h1Heading={contentArray[0].head}
						h5Heading={contentArray[0].para}
					/>

					<div>
						<div className='collection'>
							<div className='divFields'>
								<label className='labelField' htmlFor='fname'>
									First name
									<input
										value={firstname}
										onChange={e => {
											setFirstname(e.target.value)
										}}
										className='inputField'
										placeholder='Enter first name'
										type='text'
										id='fname'
										name='fname'
									/>
								</label>
							</div>

							<div className='divFields'>
								<label className='labelField' htmlFor='lname'>
									Last name
									<input
										value={lastname}
										onChange={e => {
											setLastname(e.target.value)
										}}
										className='inputField'
										placeholder='Enter last name'
										type='text'
										id='lname'
										name='lname'
									/>
								</label>
							</div>

							<div className='divFields'>
								<label className='labelField' htmlFor='pnumber'>
									Phone Number
									<input
										value={phoneNumber}
										onChange={e => {
											setPhoneNumber(e.target.value)
										}}
										className='inputField'
										placeholder='Enter phone number'
										type='text'
										id='pnumber'
										name='pnumber'
									/>
								</label>
							</div>

							<div className='divFields'>
								<label className='labelField' htmlFor='email'>
									Email Address
									<input
										value={emailAddress}
										onChange={e => {
											setEmailAddress(e.target.value)
										}}
										className='inputField'
										placeholder='Enter email address'
										type='email'
										id='email'
										name='email'
									/>
								</label>
							</div>
						</div>
					</div>

					<div className='divBtn'>
						<a
							href={`mailto:Info@99earbuds.com?subject=My Name is ${firstname} ${lastname}. and I'm interested in. My contact number is ${phoneNumber}. My email address is ${emailAddress}.`}
							// onClick={submitHandler}
							className='btn'
							style={{
								textDecoration: 'none',
							}}
						>
							Submit
						</a>
					</div>

					<div className={`${displayError ? null : 'error-txt'} `}>
						One or more fields have an error. Please check and try again.
					</div>
				</div>
			</div>

			<div
				className='footer-copyright'
				style={{
					position: 'relative',
				}}
			>
				<p>
					Copyright 2022 @ <span>99 Earbuds</span>
				</p>
				<a
					href='https://bizzclan.com/'
					target='_blank'
					className='footer-bizzclan'
					rel='noreferrer'
				>
					Website design and developed by Bmark
				</a>
			</div>
		</footer>
	)
}

export default Index
