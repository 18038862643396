import React from 'react'

const Index = ({ h1Heading, h5Heading, classStyleName, language }) => {
	return (
		<div className={`${classStyleName}`}>
			<h1
				className='heading1'
				style={{
					whiteSpace: 'pre-line',
					fontSize: '16px',
					textAlign: language === 'English' ? 'left' : 'right',
				}}
			>
				{h1Heading}
			</h1>
			<h5
				className='heading2'
				style={{
					whiteSpace: 'pre-line',
					fontSize: '13px',
					textAlign: language === 'English' ? 'left' : 'right',
				}}
			>
				{h5Heading}
			</h5>
		</div>
	)
}

export default Index
