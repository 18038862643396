import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initMainState } from './init'
import { MainSlice } from './types'

const mainSlice = createSlice({
	name: 'main',
	initialState: initMainState,
	reducers: {
		setDummy: (state: MainSlice, action: PayloadAction<string>) => {
			state.dummy = action.payload
		},
	},
})

export const { setDummy } = mainSlice.actions

export default mainSlice.reducer
