import { useEffect, useState } from 'react'
import { Container } from './styles'
import { calPinHeight } from '@common/func'
import Flipbook from '@components/Flipbook'
import Pin from '@components/Pin'
import Dummy from '@components/Dummy'
import Dummy2 from '@components/Dummy2'
import Dummy3 from '@components/Dummy3'
import RightSide from '../../components/Dummy3/RightSide/Index'
import LeftSide from '../../components/Dummy3/LeftSide/Index'
import Section2 from '../../components/ContectSections/Section2/Index'
import Section4 from '../../components/ContectSections/Section4/Index'
import Section5 from '../../components/ContectSections/Section5/Index'
import Section6 from '../../components/ContectSections/Section6/Index'
import Section7 from '../../components/ContectSections/Section7/Index'
import Section8 from '../../components/ContectSections/Section8/Index'
import Section9 from '../../components/ContectSections/Section9/Index'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import { RoutingProps } from '@routes'
import Section10 from '@components/ContectSections/Section10/section10'

gsap.registerPlugin(ScrollTrigger)

const Home: React.FC<RoutingProps> = ({ language }) => {
	const [progress2, setProgress2] = useState(0)
	const [progress3, setProgress3] = useState(0)
	const [progress4, setProgress4] = useState(0)
	const [loader, setLoader] = useState('loading')
	// timout method
	useEffect(() => {
		setTimeout(() => {
			setLoader('loaded')
		}, 5000)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '#home2',
					start: 'top 100',
					end: '=+400',
					scrub: 1,
				},
			})
			.fromTo(
				'#home2',
				{
					opacity: 1,
					fontSize: '30px',
				},
				{
					opacity: 0,
					fontSize: '50px',
					stagger: 0.2,
					duration: 1,
					ease: 'power2.inOut',
				}
			)
	}, [])

	return loader === 'loading' ? (
		<div
			style={{
				width: '100%',
				height: '100vh',
				color: 'white',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div className='loader-wrapper'>
				<div className='loader'></div>
			</div>
		</div>
	) : (
		<div id='home'>
			<Container>
				<Pin
					progress={progress2}
					setProgress={setProgress2}
					height={calPinHeight('A', 3.5)} // 1
				>
					<Flipbook id='A' progress={progress2} />
					<span id='home2'>
						<Dummy val={progress2} children={<h1>99 Earbuds</h1>} />
					</span>
				</Pin>

				<Section2 language={language} />

				<Pin
					progress={progress3}
					setProgress={setProgress3}
					height={calPinHeight('B', 3.5)} // 0.75
				>
					<Flipbook id='B' progress={progress3} size={1} />
					<Dummy2
						no={2}
						opacity={progress3}
						val={progress3}
						children={
							<p style={{ fontSize: '13px' }}>
								<span className='gray-class'>
									{language === 'English'
										? `Your world is only limited by the language you can comprehend.Now you can expand your connections with`
										: `عالمنا ُمق ّيد فقط باللغة التي يمكنك فهمها، ولكن الآن لم يعد الآمر كذلك، حيث مع تلك السماعة يمكنك توسيع معارفك وثقافاتك من خلال وصولك إلى `}{' '}
									<span className='lightgray-class'>
										99 {language === 'English' ? 'earbuds' : 'سماعة'}.
									</span>
								</span>{' '}
								{language === 'English'
									? `These earbuds provide`
									: ` حيث توفّر سماعات الأذن هذه`}{' '}
								<span className='green-class'>
									{language === 'English'
										? 'real-time translation of up to 35 languages,'
										: 'ترجمة تلقائية لما يصل إلى 35 لغة،'}
								</span>{' '}
							</p>
						}
					/>

					<Dummy2
						no={3}
						opacity={progress3}
						val={progress3}
						children={
							<p style={{ fontSize: '13px' }}>
								<span className='gray-class'>
									<span className='lightgray-class'>
										{language === 'English'
											? 'allowing you to hold conversations with anyone, anywhere in the world, without any language barriers.'
											: 'مما يسمح لك بإجراء محادثات مع أي شخص في أي مكان في العالم، ودون أي حواجز لغوية.'}
									</span>{' '}
									{language === 'English'
										? "Whether you're traveling for business or pleasure, you'll be able to communicate with ease and connect with the world like never before."
										: 'فسوا ًء كنت مساف ًرا للعمل أو للسياحة، ستتمكن من التواصل بسهولة والتواصل مع عالمك كما لم تج ّرب من قبل.'}
								</span>
							</p>
						}
					/>
				</Pin>

				<Section4 language={language} id={'aboutus'} />

				<Pin
					progress={progress4}
					setProgress={setProgress4}
					height={calPinHeight('C', 4.5)} // 2
				>
					<Flipbook id='C' progress={progress4} />
					<Dummy3
						no={2}
						opacity={progress4}
						val={progress4}
						children={<LeftSide progress={progress4} language={language} />}
					/>
					<Dummy3
						no={3}
						opacity={progress4}
						val={progress4}
						children={<RightSide progress={progress4} language={language} />}
					/>
				</Pin>

				<Section5 />

				<Section6 language={language} />
				<Section7 language={language} id={'features'} />
				{language === 'English' && <Section8 language={language} />}
				<Section9 language={language} />
				<Section10 language={language} />
			</Container>
		</div>
	)
}

export default Home
