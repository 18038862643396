import HeadingTextSection from '../../HeadingTextSection/Index'

const Index = ({ language }) => {
	const contentArray = [
		{
			key: 1,
			head: language === 'English' ? `How to Use 99 Earbuds` : `كيفية استخدام سماعة 99`,
			para: (
				<span className='gray-class'>
					{language === 'English'
						? `Using 99 earbuds is simple and straightforward. Just put them on, and you're good to go. The earbuds will automatically connect to your phone or other device and begin translating your conversations in real-time. If you need to use the earbuds offline, simply select the languages that you need to be translated ahead of time, and our earbuds will do the rest.`
						: `يعد استخدام سماعة 99 أم ًرا بسي ًطا وعمل ًيا. فقط ارتديها، وأنت على ما يرام. ستتصل سماعات الأذن تلقائ ًيا بهاتفك أو بجهاز آخر وتبدأ في ترجمة محادثاتك في الوقت الفعال. إذا كنت بحاجة إلى استخدام سماعات الأذن، فما عليك سوى اختيار اللغات التي تريد ترجمتها.`}
					<br />
					<br />
					<span className='lightgray-class'>
						{language === 'English'
							? `To get the most out of your earbuds, we recommend downloading the 99 App.`
							: `لتحقيق أقصى استفادة من سماعات الأذن، نوصيك بتنزيل تطبيق 99.`}
					</span>{' '}
					<span className='green-class'>
						{language === 'English'
							? `The app will provide you with an easy way to manage your translations, earbuds, and smart assistant.`
							: `سيوفر لك التطبيق طريقة سهلة لإدارة الترجمات وسماعات الأذن والمساعد الذكي.`}
					</span>
					<br />
					<br />
					<span className='lightgray-class'>
						{language === 'English'
							? `With the app, you can also customize your earbud settings to suit your needs. The 99 App is available on the Apple App Store and Google Play.`
							: `باستخدام التطبيق، يمكنك أي ًضا تخصيص إعدادات سماعة الأذن لتناسب احتياجاتك تطبيق 99 متاح في غوغل بلاي و آب ستور.`}
					</span>
				</span>
			),
		},
	]

	return (
		<section className='contentSection removeBorder'>
			<div className='contentSection-container section9-txt'>
				{/* <h1>App</h1> */}
				<HeadingTextSection
					language={language}
					key={contentArray[0].key}
					h1Heading={contentArray[0].head}
					h5Heading={contentArray[0].para}
				/>
			</div>
		</section>
	)
}

export default Index
