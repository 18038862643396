import { css } from '@emotion/react'

export default css`
	@import url('https://fonts.googleapis.com/css2?family=Lora:wght@300;400;600&family=Outfit:wght@300;400;600&display=swap');

	html {
		min-height: 100%;
		overflow-y: scroll;
		display: flex;
		-webkit-overflow-scrolling: touch;
		background-color: black;
	}

	body {
		display: flex;
		max-width: 100%;
		flex-grow: 1;
		margin: 0;
		font-family: 'Outfit', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		user-select: none;
		white-space: pre-line !important;
	}

	#root {
		/* display: flex; */
		max-width: 100%;
		flex-grow: 1;
	}

	* {
		box-sizing: border-box;
	}

	/* ***************** Declaring Global Variables ***************** */
	:root {
		/* Text Colors */
		--main-txt-color: white;
		--sec-txt-color: lightgray;
		--thri-txt-color: gray;

		/* Background Color */
		--main-background-color: black;

		/* Font Size */
		--main-h1-font-size: 65px;
		--sec-h1-font-size: 55px;
		--thri-h1-font-size: 45px;
		--fourth-h1-font-size: 35px;

		--main-h3-font-size: 40px;
		--sec-h3-font-size: 30px;
		--thri-h3-font-size: 20px;
		--fourth-h3-font-size: 10px;

		--main-h5-font-size: 30px;
		--sec-h5-font-size: 25px;
		--thri-h5-font-size: 20px;
		--fourth-h5-font-size: 15px;

		/* Font Weight */
		--main-font-weight: bolder;
		--sec-font-weight: bold;
		--thri-font-weight: normal;

		/* Padding */
		--main-section-padding: 50px 50px;
		--sec-section-padding: 30px 20px;
		--thri-section-padding: 20px 20px;

		--main-top-padding: 50px;
		--sec-top-padding: 30px;
		--thri-top-padding: 20px;

		--main-bottom-padding: 50px;
		--sec-bottom-padding: 30px;
		--thri-bottom-padding: 20px;

		/* Margin */
		--main-section-margin: 50px 50px;
		--sec-section-margin: 30px 20px;
		--thri-section-margin: 20px 20px;

		--main-vertical-margin: 50px 0px;
		--sec-vertical-margin: 30px 0px;
		--thri-vertical-margin: 20px 0px;

		--main-top-margin: 50px;
		--sec-top-margin: 30px;
		--thri-top-margin: 20px;

		--main-bottom-margin: 50px;
		--sec-bottom-margin: 30px;
		--thri-bottom-margin: 20px;

		/* Border Top and Border Bottom */
		--main-border-height: 0.5px solid gray;

		/* Width && Height */
		--main-max-width: 1000px;
		--main-max-height: 100vh;

		--sec-max-width: 850px;
		--sec-max-height: 90vh;

		--thri-max-width: 650px;
		--thri-max-height: 80vh;
	}

	/* ***************** Body ***************** */
	/* body {
		background-color: #000;
		font-family: 'Roboto', 'sans-serif';
		white-space: pre-line !important;
		overflow-x: hidden;
	} */

	.green-class {
		color: #963631;
	}

	.gray-class {
		color: #6e6e73;
	}

	.lightgray-class {
		color: white;
	}

	/* ***************** Page ***************** */

	/* ***************** Remove Border ***************** */
	.removeBorder {
		border-bottom: none !important;
	}

	.footerHead_text h1,
	.section9-txt h1 {
		font-size: 45px !important;
	}

	/* ***************** Dummy3 ***************** */

	.dummy3Right,
	.dummy3Left {
		-webkit-animation: fadein 300ms linear 1 normal forwards;
	}

	@-webkit-keyframes fadein {
		from {
			opacity: 0;
			transform: translatey(30%);
		}
		to {
			opacity: 1;
			transform: translatey(0%);
		}
	}

	/* ***************** Section5 ***************** */

	.section5-video {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;
		position: relative;
	}

	.section5-video video {
		aspect-ratio: 1;
		width: 100%;
	}

	.section5-video img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) !important;
		height: 700px !important;
	}

	@-webkit-keyframes spaceboots {
		0% {
			-webkit-transform: translate(2px, 1px) rotate(0deg) !important;
		}
		10% {
			-webkit-transform: translate(-1px, -2px) rotate(-1deg) !important;
		}
		20% {
			-webkit-transform: translate(-3px, 0px) rotate(1deg) !important;
		}
		30% {
			-webkit-transform: translate(0px, 2px) rotate(0deg) !important;
		}
		40% {
			-webkit-transform: translate(1px, -1px) rotate(1deg) !important;
		}
		50% {
			-webkit-transform: translate(-1px, 2px) rotate(-1deg) !important;
		}
		60% {
			-webkit-transform: translate(-3px, 1px) rotate(0deg) !important;
		}
		70% {
			-webkit-transform: translate(2px, 1px) rotate(-1deg) !important;
		}
		80% {
			-webkit-transform: translate(-1px, -1px) rotate(1deg) !important;
		}
		90% {
			-webkit-transform: translate(2px, 2px) rotate(0deg) !important;
		}
		100% {
			-webkit-transform: translate(1px, -2px) rotate(-1deg) !important;
		}
	}

	.shake {
		-webkit-animation-name: spaceboots !important;
		-webkit-animation-duration: 0.8s !important;
		-webkit-transform-origin: 50% 50% !important;
		-webkit-animation-iteration-count: infinite !important;
		-webkit-animation-timing-function: linear !important;
	}

	/* ***************** Scenes 1, 2, 3 && Section 8 ***************** */
	/* ***************** Sections 1, 3, 5, 8 ***************** */

	.sectionNew,
	.section3,
	.section5 {
		height: 100vh;
		position: relative;
	}

	.sectionNew video,
	.section3 video,
	.section5 video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.sectionNew h1,
	.section3 p,
	.section5 .section5ParaLeft,
	.section5 .section5ParaRight,
	.section8-img .heading1,
	.section8-img .heading2 {
		position: absolute;
		color: var(--sec-txt-color);
		transform: translate(-50%, -50%);
	}

	.sectionNew h1 {
		font-size: 70px;
		top: 20%;
		left: 50%;
	}

	.section3 p,
	.section5 .section5ParaLeft,
	.section5 .section5ParaRight,
	.section8 .heading2 {
		width: 250px;
		font-size: var(--fourth-h5-font-size) !important;
	}

	.section3 p,
	.section5 .section5ParaLeft,
	.section5 .section5ParaRight {
		top: 50%;
	}

	.section3 p,
	.section5 .section5ParaLeft,
	.section8 .heading2 {
		left: 20%;
	}

	.section5 .section5ParaRight {
		left: 80%;
	}

	.section8 .heading2 {
		top: 75%;
	}

	/* ***************** Sections 2, 4, 6, 7 ***************** */
	.contentSection {
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: var(--main-border-height);
		border-bottom: var(--main-border-height);
	}

	.contentSection-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: var(--main-max-width);
		margin: var(--main-vertical-margin);
	}

	.contentSection-container h1 {
		color: var(--main-txt-color) !important;
		font-size: var(--main-h1-font-size) !important;
		font-weight: var(--main-font-weight) !important;
		margin: var(--main-vertical-margin) !important;
	}

	.contentSection-container h5 {
		opacity: 0.8;
		color: var(--sec-txt-color) !important;
		font-size: var(--sec-h5-font-size) !important;
		font-weight: var(--thri-font-weight) !important;
		margin-bottom: var(--main-bottom-margin) !important;
	}

	.contentSection-container img,
	.contentSection-container video {
		height: 500px;
	}

	/* ***************** Sections 8 ***************** */
	.section8 {
		align-items: initial !important;
	}

	.section8-container {
		margin: 0px 0px var(--main-bottom-margin) 0px !important;
	}

	.section8-img {
		position: relative;
	}

	.section8-img .heading1 {
		top: 60%;
		left: 50%;
		width: max-content;
		font-size: var(--sec-h1-font-size) !important;
	}

	.section8 .heading2 {
		top: 75%;
		left: 25%;
	}

	/* ***************** Media Quries ***************** */

	/* ***************** 1000px ***************** */
	@media (max-width: 1000px) {
		.contentSection-container {
			padding: 0px 15px;
		}

		.section5-video img,
		.contentSection-container img,
		.contentSection-container video {
			height: 450px !important;
		}

		.footer-container {
			min-height: 450px;
			max-width: 900px;
		}
	}

	/* ***************** 850px ***************** */
	@media (max-width: 850px) {
		.section3 {
			height: var(--sec-max-height);
			display: flex;
			position: initial;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.section3 video {
			margin-top: 50px;
		}

		.section3 p {
			transform: none;
			position: initial;
			width: 90%;
			margin-top: var(--thri-top-margin);
		}

		.section5-video img,
		.contentSection-container img,
		.contentSection-container video {
			height: 400px !important;
		}

		.section8-img .heading1 {
			font-size: var(--main-h3-font-size) !important;
		}

		.footer-container {
			max-width: 800px;
		}

		.collection {
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	/* ***************** 750px ***************** */
	@media (max-width: 750px) {
		body {
			-webkit-overflow-scrolling: touch !important;
		}

		.sectionNew {
			height: var(--sec-max-height);
			display: flex;
			position: initial;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.section1 video {
			margin-top: 50px;
		}

		.sectionNew h1 {
			transform: none;
			position: initial;
			width: auto;
			margin-top: var(--thri-top-margin);
		}

		.sectionNew h1,
		.contentSection-container h1 {
			font-size: var(--sec-h1-font-size) !important;
		}

		.contentSection-container h5 {
			font-size: var(--thri-h5-font-size) !important;
			font-weight: var(--thri-font-weight) !important;
		}

		.section5-video img,
		.contentSection-container img,
		.contentSection-container video {
			height: 350px !important;
		}

		.section8-img .heading1 {
			font-size: var(--main-h5-font-size) !important;
		}

		/* .dummy3Right {
			-webkit-animation: fadeinR 300ms linear 1 normal forwards;
		}

		@-webkit-keyframes fadeinR {
			from {
				opacity: 0;
				transform: translatex(100%);
			}
			to {
				opacity: 1;
				transform: translatex(0%);
			}
		}

		.dummy3Left {
			-webkit-animation: fadeinL 300ms linear 1 normal forwards;
		}

		@-webkit-keyframes fadeinL {
			from {
				opacity: 0;
				transform: translatex(-100%);
			}
			to {
				opacity: 1;
				transform: translatex(0%);
			}
		} */

		@-webkit-keyframes fadeinL {
			from {
				opacity: 0;
				transform: translatey(5%);
			}
			to {
				opacity: 1;
				transform: translatey(0%);
			}
		}

		@-webkit-keyframes fadeinR {
			from {
				opacity: 0;
				transform: translatey(-5%);
			}
			to {
				opacity: 1;
				transform: translatey(0%);
			}
		}
	}

	/* ***************** 600px ***************** */
	@media (max-width: 600px) {
		.sectionNew h1,
		.contentSection-container h1 {
			font-size: var(--thri-h1-font-size) !important;
			font-weight: var(--sec-font-weight) !important;
		}

		.section5 {
			height: var(--sec-max-height) !important;
			display: flex;
			/* flex-direction: column-reverse; */
			position: initial;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.section5 video {
			margin-top: 50px;
		}

		.section5 .section5-container {
			position: relative;
		}

		.section5 .section5Para {
			top: 0px;
			display: none;
			color: white;
			position: initial;
			position: absolute;
			font-size: var(--fourth-h3-font-size);
			transition: none !important;
		}

		.section5 .section5ParaLeft,
		.section5 .section5ParaRight {
			display: none;
		}

		.section5 .section5para h1 {
			color: var(--sec-txt-color) !important;
		}

		.section5 .section5para h5 {
			color: var(--thri-txt-color) !important;
		}

		.contentSection-container img,
		.contentSection-container video {
			height: 275px;
		}

		.sectionNew h1,
		.contentSection-container h1 {
			font-size: var(--thri-h1-font-size) !important;
			font-weight: var(--sec-font-weight) !important;
		}
	}

	/* ***************** 500px ***************** */
	@media (max-width: 500px) {
		.section5-video img,
		.contentSection-container img,
		.contentSection-container video {
			height: 200px !important;
		}

		.sectionNew h1,
		.contentSection-container h1 {
			font-size: var(--sec-h3-font-size) !important;
		}

		.contentSection-container h5 {
			font-size: var(--thri-h5-font-size) !important;
		}

		.section8 {
			position: initial !important;
		}

		.section8-img {
			display: flex;
			justify-content: center;
			flex-direction: column;
			position: initial !important;
		}

		.section8 .heading1,
		.section8 .heading2 {
			position: inherit !important;
			transform: none !important;
			white-space: inherit !important;
			width: 90% !important;
		}
	}
	.spinner {
		width: 80px;
		height: 80px;
		position: relative;
		margin: 100px auto;
	}

	.bounce1,
	.bounce2,
	.bounce3 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: #963631;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;

		-webkit-animation: sk-bounce 1.4s infinite ease-in-out;
		animation: sk-bounce 1.4s infinite ease-in-out;
	}

	.bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}

	@-webkit-keyframes sk-bounce {
		0%,
		100% {
			-webkit-transform: scale(0);
		}
		50% {
			-webkit-transform: scale(1);
		}
	}

	@keyframes sk-bounce {
		0%,
		100% {
			transform: scale(0);
			-webkit-transform: scale(0);
		}
		50% {
			transform: scale(1);
			-webkit-transform: scale(1);
		}
	}

	.carousel-container {
		width: 100vw !important;
	}

	@media (min-width: 1024px) {
		.carousel-container {
			width: 100% !important;
		}
	}

	.loader-wrapper {
		top: 0;
		left: 0;
		z-index: 999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.loader {
			width: 100px;
			height: 100px;
			background: linear-gradient(45deg, transparent, transparent 40%, #f48403);
			border-radius: 50%;
			position: relative;
			animation: loader 1s linear infinite;

			&::before {
				position: absolute;
				content: '';
				top: 3px;
				right: 3px;
				left: 3px;
				bottom: 3px;
				background: #232831;
				border-radius: 50%;
				z-index: 1;
			}

			&:after {
				position: absolute;
				content: '';
				top: 0px;
				right: 0px;
				left: 0px;
				bottom: 0px;
				background: linear-gradient(45deg, transparent, transparent 40%, #f48403);
				border-radius: 50%;
				filter: blur(20px);
			}
		}
	}

	@keyframes loader {
		0% {
			transform: rotate(0deg);
			filter: hue-rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
			filter: hue-rotate(360deg);
		}
	}
`
