import cloneDeep from 'lodash.clonedeep'

import metaFrames from '@assets/meta/frames'

interface IImage {
	url: string
	loaded: boolean
	el?: HTMLImageElement
}

interface IImagesMap {
	[key: string]: IImage
}

const width = () => window.innerWidth

let initialized = false
let loading = false

let images: IImagesMap = {}

const importFrame = (name: string): Promise<any> => {
	if (width() >= 701) return import(`@assets/dekFrames2/${name}`)
	return import(`@assets/mobFrames2/${name}`)
}

export const wait = (ms: number) => new Promise((resolve, _) => setTimeout(resolve, ms))

export const getImages = () => {
	if (!initialized) return null
	return cloneDeep(images)
}

export const getImgEl = async (name: string) => {
	while (!images[name]?.loaded) await wait(300)
	return images[name].el || null
}

export const initialize = async () => {
	if (initialized) return

	const keys = Object.keys(metaFrames)

	let unresolved: Promise<void>[] = []

	keys.forEach(key => {
		const total = metaFrames[key]

		const promises = Array.from({ length: total }, async (_, i) => {
			const n = (i + 1).toLocaleString('en-US', {
				minimumIntegerDigits: 3,
			})

			const name = `${key}_${n}.jpg`
			const url = (await importFrame(name)).default

			images[name] = { url, loaded: false }
		})

		unresolved = unresolved.concat(promises)
	})

	await Promise.all(unresolved)
	initialized = true
}

export const loadImage = async (url: string) => {
	const imgEl = new Image()

	const loaded = new Promise<void>(resolve => {
		imgEl.onload = () => resolve()
	})

	imgEl.src = url

	await loaded

	return imgEl
}

export const loadImages = async () => {
	if (loading) return

	await initialize()

	const keys = Object.keys(images)
	const values = Object.values(images)
	if (values.every(({ loaded }) => loaded)) return

	loading = true

	await values.reduce(async (a, { url, loaded }, i) => {
		await a
		if (loaded) return true

		const key = keys[i]
		const el = await loadImage(url)

		images[key] = { url, loaded: true, el }
		return true
	}, Promise.resolve(true))

	loading = false
}
