import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from '@pages/Home'

export interface RoutingProps {
	language: string
}

const Routing: React.FC<RoutingProps> = ({ language }) => (
	<Suspense>
		<Routes>
			<Route path='/' element={<Home language={language} />} />
		</Routes>
	</Suspense>
)

export default Routing
