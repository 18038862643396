import { useState } from 'react'
import { Link } from 'react-scroll'

import logo from '../../assets/images/logo99.png'
import './style.css'

const Index = ({ setLanguage, language }) => {
	const [isActive, setIsActive] = useState(false)

	return (
		<div>
			<div className={`nav-container ${isActive ? 'active' : null}`}>
				<nav>
					<ul
						className='mobile-nav'
						style={{
							position: 'relative',
						}}
					>
						<li>
							<div onClick={() => setIsActive(!isActive)} className='menu-icon-container'>
								<div className='menu-icon'>
									<span className='line-1'></span>
									<span className='line-2'></span>
								</div>
							</div>
						</li>

						<li
							className='menu-icon-container'
							style={{
								position: 'absolute',
								left: '50%',
								transform: 'translateX(-50%)',
								right: '50%',
							}}
						>
							<img style={{ height: '30px', marginTop: '5px' }} src={logo} alt='' />
						</li>
					</ul>

					<ul className={`desktop-nav  ${isActive ? 'mobile_Nav' : null}`}>
						<div className='logo'>
							<li>
								<Link to='home' spy={true} smooth={true}>
									<img style={{ height: '30px', marginTop: '5px' }} src={logo} alt='' />
								</Link>
							</li>
						</div>

						<div className={`nav-links ${isActive ? 'nav-mobile-version' : null}`}>
							<li>
								<Link to='home' spy={true} smooth={true}>
									Home
								</Link>
							</li>
							<li>
								<Link to='aboutus' spy={true} smooth={true}>
									About Us
								</Link>
							</li>
							<li>
								<Link to='features' spy={true} smooth={true}>
									Features
								</Link>
							</li>
							<li>
								<Link to='contactus' spy={true} smooth={true}>
									Contact US
								</Link>
							</li>
							<li>
								<button
									onClick={() =>
										setLanguage(language === 'English' ? 'Arabic' : 'English')
									}
									className='language'
								>
									{language === 'English' ? 'العربية' : 'English'}
								</button>
							</li>
						</div>
					</ul>
				</nav>
			</div>

			<div className='overlay'></div>
		</div>
	)
}

export default Index
