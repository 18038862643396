import React, { useEffect, useState } from 'react'
import v from '../../../assets/images/3rdvideoChnge2.mp4'
import v1 from '../../../assets/images/partial.png'

const Index = () => {
	const [videoTimer, setVideoTimer] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			// console.log("Hello me after 4 sec")
			setVideoTimer(true)
		}, 4000)
		return () => clearTimeout(timer)
	}, [])

	return (
		<section className='section5-video'>
			{videoTimer && (
				<React.Fragment>
					<video src={v} preload='none' loop playsInline autoPlay muted />
					<img className='shake' src={v1} alt='' />
				</React.Fragment>
			)}
		</section>
	)
}

export default Index
