import HeadingTextSection from '../../HeadingTextSection/Index'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import { useEffect } from 'react'

gsap.registerPlugin(ScrollTrigger)

const Index = ({ id, language }) => {
	const contentArray = [
		{
			key: 1,
			head: language === 'English' ? `About Us` : 'المزيد عنّا',
			para: (
				<span className='gray-class '>
					<span className='mainDiiv'>
						<span
							className='d1'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? 'We believe communication should be easy and effortless, and our earbuds are the perfect way to make that happen.'
								: 'إنطلاقاً من اعتقادنا بكون التواصل يجب أن يكون سهلاً وغير ُمجهد؛ كانت سماعات الأذن الخا ّصة بنا هي الطريقة ال ُمثلى لتحقيق ذلك.'}{' '}
						</span>
						<span
							className='d1'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? "We're dedicated to making earbuds that transcend language barriers and help people connect with the world."
								: ' نح ُن ملتزمون بصنع سماعات أذن تتجاوز حواجز اللغة وتساعد الناس على التواصل مع العالم.'}{' '}
						</span>
						<span
							className='d1'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? 'Designed with the'
								: 'تم تصميم سماعة 99 بأحدث تقنيات الذكاء الاصطناعي والتي تتم ّيز بالترجمة الفورية، الترجمة دون الحاجة لاتصال إنترنت، ترجمة الصور، ومساعد ذكي، ك ّل هذا طي تساعدك على التواصل مع العالم وتوسيع آفاقك.'}{' '}
						</span>
						{language === 'English' && (
							<span className='green-class'>
								latest AI technology and featuring real-time translation, offline
								translation, image translation, and a smart assistant
							</span>
						)}
						{language === 'English' && (
							<span className='lightgray-class'> 99 earbuds</span>
						)}{' '}
						{language === 'English' && (
							<span
								className='d1'
								style={{
									opacity: 0.75,
								}}
							>
								{' '}
								will help you connect with the world and expand your horizons.{' '}
							</span>
						)}
					</span>
					<br />
					<br />{' '}
					<span className='d2Main'>
						<span className='d2'>
							{language === 'English'
								? `The 99 earbuds are a product of the Bmark company based in Istanbul, Turkey.`
								: 'سماعة 99 هي إحدى منتجات شركة بي-مارك ومقرها في اسطنبول، تركيا.'}
						</span>{' '}
						<span
							className='d2'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? `We pride ourselves on excellent customer service and quality products that empower our customers.`
								: ' نحن نس ّخر أنفسنا لخدمة العملاء باحترافيّة غير متناهية، ونسعى إلى تقديم المنتجات عالية الجودة التي تفيد عملائنا.'}{' '}
						</span>
						<span
							className='d2'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? `The world is changing fast, and we're committed to helping our customers stay ahead of the curve.`
								: `العال ُم يتغ ّير بسرعة، ونحن ملتزمون بمساعدة عملائنا على البقاء في الصدارة لمواكبة ذلك التغ ّير.`}{' '}
						</span>
					</span>
					<br /> <br />
					<span className='d3Main'>
						<span
							className='d3'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? `Our company is built on trust, connection, and innovation, three values that guide everything we do.`
								: `شركتنا مبنية على 3 قيم أساسيّة: الثقة، الاتصال، والابتكار في ُكل ما نقوم به من تفاصيل خلال رحلتنا من تطوير.`}{' '}
						</span>
						<span
							className='d3'
							style={{
								opacity: 0.75,
							}}
						>
							{language === 'English'
								? `From product development to user experience, we're always thinking about how we can make our earbuds better and help our customers engage with the global community through revolutionary technology.`
								: `المنتج وإلى تجربة المستخدم؛ نُف ّكر دائ ًما في كيفية تحسين سماعات الأذن لدينا ومساعدة عملائنا على التفاعل مع المجتمع العالمي من خلال التكنولوجيا الثورية.`}
						</span>
					</span>
				</span>
			),
		},
		{
			key: 2,
			head:
				language === 'English'
					? `Product Features and Specifications.`
					: `ميزات المنتج ومواصفاته`,
			para: (
				<span className='gray-class d4Main'>
					<span className='d4'>
						{language === 'English'
							? `Not all earbuds are created equal.`
							: `ليست كل سماعات الأذن متشابهة.`}{' '}
					</span>
					<span className='d4'>
						{language === 'English'
							? `If you're looking for earbuds that will help you communicate and`
							: ` إذا كنت تبحث عن سماعات أذن ُمبتكرة وتساعدك على التواصل والترجمة`}{' '}
					</span>
					<span className='d4'>
						{language === 'English'
							? `translate in different languages while you travel and/or do business, then you need`
							: `بلغات مختلفة أثناء السفر أو القيام بمهامك المهن ّية واليوم ّية، فأنت بحاجة إلى سماعة 99 بدون ش ّك.`}
					</span>{' '}
					{language === 'English' && <span className='lightgray-class'>99 earbuds.</span>}
				</span>
			),
		},
	]

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.mainDiiv',
					start: 'top center',
					end: '+=200',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d1', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d1',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.d2Main',
					start: 'top center',
					end: '+=100',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d2', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d2',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.d3Main',
					start: 'top center',
					end: '+=100',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d3', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d3',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.d4Main',
					start: 'top center',
					end: '+=100',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d4', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d4',
				{
					color: '#6e6e73',
					opacity: 0.75,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	return (
		<section id={id} className='contentSection'>
			<div className='contentSection-container'>
				{contentArray.map(({ key, head, para }) => (
					<HeadingTextSection
						language={language}
						key={key}
						h1Heading={head}
						h5Heading={para}
						classStyleName=''
					/>
				))}
			</div>
		</section>
	)
}

export default Index
