import React, { useEffect } from 'react'
import HeadingTextSection from '../../HeadingTextSection/Index'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const Index = ({ id, language }) => {
	const contentArray = [
		{
			key: 1,
			head: language === 'English' ? `For Avid Travelers` : `للمسافرين المتحمسين`,
			para: (
				<span className='gray-class main1'>
					<span className='d1'>
						{language === 'English'
							? `If you're an avid traveler, you know how important it is to`
							: `إذا كنت مساف ًرا متعط ًشا؛ فأنت تعلم مدى أهمية التواصل`}{' '}
					</span>
					<span className='green-class'>
						{language === 'English'
							? `communicate with people worldwide.`
							: `مع الناس في جميع أنحاء العالم.`}
					</span>{' '}
					<span className='d1'>
						{' '}
						{language === 'English' ? `With` : `مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, you'll be able to do just that.`
							: ` ستتمكن من فعل ذلك تما ًما.`}
					</span>{' '}
					<span className='d1'>
						{language === 'English'
							? `Whether you're trying to build rapport with a new business partner or make friends while you're on vacation, our earbuds will help you communicate in various languages.`
							: `سواء كنت تحاول بناء علاقة مع شريك تجاري جديد أو تكوين صداقات أثناء إجازتك، ستساعدك سماعات الأذن لدينا على التواصل بلغات مختلفة.`}
					</span>{' '}
					<span className='d1'>
						{language === 'English'
							? `Imagine going on a trip without having to worry about how you'll read the menu or find the best directions to your next landmark.`
							: `تخيل فقط الذهاب في رحلة دون الحاجة إلى القلق بشأن كيفية قراءة قائمة مطعم أو العثور على أفضل الطرق للوصول إلى وجهتك التالية. `}
					</span>{' '}
					<span className='d1'>
						{language === 'English' ? `With` : ` أيضاً مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, you'll be able to have conversations with people from all over the world without any difficulties.`
							: ` ستتمكن من إجراء محادثات مع أشخاص من جميع أنحاء العالم دون أي صعوبات.`}
					</span>{' '}
					<span className='d1'>
						{language === 'English'
							? `If you're looking for earbuds that will help you communicate and translate in different languages while you travel, the`
							: `إذا كنت تبحث عن سماعات أذن تساعدك على التواصل والترجمة بلغات مختلفة أثناء السفر،`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `فإ ّن سماعة `}
						</span>{' '}
						{language === 'English' ? `are great for you.` : `رائعة بالنسبة لك.`}
					</span>
				</span>
			),
		},
		{
			key: 2,
			head: language === 'English' ? `For Business Professionals` : `لمتخصصي الأعمال`,
			para: (
				<span className='gray-class main2'>
					<span className='d2'>
						{' '}
						{language === 'English'
							? `In a highly connected world, there are more opportunities to scale your business across the globe, but how can you overcome the language barriers? With`
							: `في عالمنا الحال ّي المربوط للغاية، هناك المزيد من الفرص لتوسيع نطاق عملك في جميع أنحاء العالم، ولكن كيف يمكنك التغلب على حواجز اللغة؟`}{' '}
					</span>
					<span className='lightgray-class'>
						99 {language === 'English' ? `earbuds` : `مع سماعة`}
					</span>
					,{' '}
					<span className='d2'>
						{language === 'English' ? `you can have a` : ` يمكنك الحصول`}
					</span>
					<span className='green-class'>
						{' '}
						{language === 'English'
							? `real-time translation of your business calls without lag time.`
							: `على ترجمة فورية لمكالمات عملك دون تأخير. `}
					</span>{' '}
					<span className='d2'>
						{language === 'English'
							? `This is perfect for conference calls with clients or partners who speak different languages.`
							: `وهو ح ّل مثال ّي للمكالمات الجماعية مع العملاء أو الشركاء الذين يتحدثون لغات مختلفة.`}
					</span>{' '}
					<span className='d2'>
						{language === 'English'
							? `The image translation also comes in handy when reading foreign documents, contracts, or agreements.`
							: `علاوةً على أ ّن ترجمة الصور مفيدة أي ًضا عند قراءة المستندات أو العقود أو الاتفاقيات الأجنبية.`}
					</span>{' '}
					<span className='d2'>
						{language === 'English'
							? `Simply take a photo of the document, and earbuds will provide an accurate translation.`
							: `ما عليك سوى التقاط صورة للمستند وستوفر سماعات الأذن ترجمة دقيقة.`}
					</span>{' '}
					<span className='d2'>
						{language === 'English' ? `With` : `مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, you'll be doing international business	like a pro in no time.`
							: `سوف تقوم بأعمال تجارية دولية كرجال الأعمال في أي وقت من الأوقات`}
					</span>
				</span>
			),
		},
		{
			key: 3,
			head: language === 'English' ? `For Language Learners` : `لمتعلمي اللغة`,
			para: (
				<span className='gray-class main3'>
					<span className='d3'>
						{language === 'English'
							? `Are you interested in learning a new language but find it difficult to find the time or resources to do so? With`
							: `هل أنت مهتم بتعلم لغة جديدة ولكنك تجد صعوبة في إيجاد الوقت أو الموارد للقيام بذلك؟ مع `}{' '}
					</span>
					<span className='lightgray-class'>
						99{language === 'English' ? `earbuds` : `سماعة`}
					</span>
					,{' '}
					<span className='d3'>
						{language === 'English'
							? `you can learn any language while on the go.`
							: `تعلّم اللغة لم يعد صعباً، حي ُث يمكنك تعلم أي لغة أثناء التنقل.`}
					</span>{' '}
					<span className='d3'>
						{language === 'English'
							? `To use earbuds for language learning, simply select the language that you want to learn, and earbuds will provide real-time translation and pronunciation assistance.`
							: `لاستخدام سماعات الأذن لتعلم اللغة، ما عليك سوى تحديد اللغة التي تريد تعلمها، وستوفر سماعات الأذن المساعدة في الترجمة والنطق بشكل مباشر.`}{' '}
					</span>
					<span className='d3'>
						{language === 'English'
							? `You can also use the earbuds to listen to foreign news or podcasts, and they'll help you understand and follow along.`
							: `يمكنك أي ًضا استخدام سماعات الأذن للاستماع إلى الأخبار أو البودكاست الأجنبية، وستساعدك على فهم ومتابعة لغتك التي تحتاجها.`}
					</span>{' '}
					<span className='d3'>
						{language === 'English'
							? `The earbuds' smart assistant feature is also perfect for language learners.`
							: `تعد ميزة المساعد الذكي لسماعات الأذن مثالية أي ًضا لمتعلمي اللغة.`}{' '}
					</span>
					<span className='green-class'>
						{language === 'English'
							? `The smart assistant can help you with vocabulary, grammar, and pronunciation.`
							: `يمكن أن يساعدك المساعد الذكي في المفردات والقواعد والنطق.`}
					</span>{' '}
					<span className='d3'>
						{language === 'English'
							? `If you want to learn a new language quickly, earbuds are the way to go.`
							: `إذا كنت تريد تعلم لغة جديدة بسرعة، فإن سماعة 99 هي سبيلك الأول لذلك.`}
					</span>
				</span>
			),
		},
		{
			key: 4,
			head: language === 'English' ? `Futuristic Design` : 'تصميم عصري',
			para: (
				<span className='gray-class main4'>
					<span className='lightgray-class'>
						{language === 'English' ? '99 earbuds' : 'سماعة 99'}
					</span>{' '}
					<span className='d4'>
						{' '}
						{language === 'English'
							? 'are functional and designed to look good and feel comfortable.'
							: 'هي سماعى أذن عمل ّية و ُمصممة لتبدو بأعلى درجات الأناقة والراحة'}
					</span>{' '}
					<span className='d4'>
						{language === 'English'
							? 'They come in a sleek black color and an ergonomic design that fits snugly in your ear. With'
							: '.تأتي باللون الأسود الأنيق وبتصميم مريح يلائم أذنك بشكل ُمرضي'}{' '}
					</span>
					<span className='green-class'>
						{language === 'English'
							? 'up to 4 hours of battery life,'
							: 'مع بطارية بعمر طويل تتيح لك استخدام يصل إلى 4 ساعات،'}
					</span>{' '}
					<span className='d4'>
						{' '}
						{language === 'English'
							? 'you can use them all day without having to worry about recharging.'
							: 'حيث يمكنك استخدامها طوال اليوم دون الحاجة إلى التفكير بضرورة شحنها أو توقّفها عن العمل.'}
					</span>{' '}
					<span className='d4'>
						{language === 'English'
							? "If you're looking for earbuds that will help you connect in style, look no further than"
							: ' فإذا كنت تبحث عن سماعات أذن تساعدك على الاتصال بالعالم من جديد وبتصميم مثال ّي؛ فابحث عن'}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? 'earbuds' : 'سماعة.'}
						</span>
						.
					</span>
				</span>
			),
		},
		{
			key: 5,
			head: language === 'English' ? `For Students` : `للطلاب`,
			para: (
				<span className='gray-class main5'>
					<span className='d5'>
						{language === 'English'
							? `Do you have difficulty understanding your lectures or taking notes in a foreign language class?`
							: `هل تجد صعوبة في فهم محاضراتك أو تدوين الملاحظات في جامعتك أو فصلك الدراسي؟`}
					</span>{' '}
					<span className='d5'>
						{language === 'English' ? `With` : `مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, all you have to do is put them on, and they'll translate the lecture in real-time.`
							: ` كل ما عليك فع هو أن تضعها؛ وهي ستقوم بترجمة المحاضرة لك بشكل فور ّي ومباشر.`}
					</span>{' '}
					<span className='d5'>
						{language === 'English'
							? `You can also use the earbuds for studying purposes.`
							: `يمكنك أي ًضا استخدام سماعات الأذن لأغراض الدراسة.`}
					</span>{' '}
					<span className='d5'>
						{language === 'English'
							? `If you're reading a textbook or listening to a language-learning audio, earbuds can help you better understand and retain the information.`
							: `إذا كنت تقرأ كتا ًبا دراس ًيا أو تستمع إلى صوت لتعلم اللغة، فيمكن أن تساعدك سماعات الأذن على فهم المعلومات والاحتفاظ بها بشكل أفضل.`}
					</span>{' '}
					<span className='d5'>
						{language === 'English'
							? `The earbuds' smart assistant feature is perfect for students who need extra help organizing their schedules or taking notes.`
							: `تعد ميزة المساعد الذكي لسماعات الأذن مثالية للطلاب الذين يحتاجون إلى مساعدة إضافية في تنظيم جداولهم أو تدوين الملاحظات.`}{' '}
					</span>
					<span className='green-class'>
						{language === 'English'
							? `The smart assistant can read your class schedule aloud, set alarms, and take notes.`
							: `يمكن للمساعد الذكي قراءة جدول الفصل الدراسي بصوت عا ٍل وضبط المنبهات وتدوين الملاحظات.`}
					</span>{' '}
					<span className='d5'>
						{language === 'English' ? `With` : `مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, you'll be getting a's in no time.`
							: ` ستحصل على أعلى الدرجات بدون ش ّك.`}
					</span>
				</span>
			),
		},
		{
			key: 6,
			head: language === 'English' ? `For Multilingual Families` : ``,
			para: (
				<span className='gray-class main6'>
					<span className='d6'>
						{language === 'English'
							? `Do you have difficulty communicating with your grandparents or other family members who speak a different language?`
							: `هل تجد صعوبة في التواصل مع أجدادك أو أفراد الأسرة الآخرين الذين يتحدثون لغة مختلفة؟ `}
					</span>{' '}
					<span className='d6'>
						{language === 'English' ? `With` : `مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, you'll be able to have conversations with them without any difficulties.`
							: `ستتمكن من إجراء محادثات معهم دون أي صعوبات.`}
					</span>{' '}
					<span className='d6'>
						{language === 'English'
							? `The earbuds' real-time translation feature will allow you to understand and be understood by your loved ones.`
							: `ستتيح لك ميزة الترجمة في الوقت الفعلي لسماعات الأذن أن تفهم ويفهمك أحبائك. `}
					</span>{' '}
					<span className='d6'>
						{language === 'English'
							? `You can also use the earbuds to`
							: `يمكنك أي ًضا استخدام سماعات`}{' '}
						<span className='green-class'>
							{language === 'English'
								? `listen to foreign news or podcasts,`
								: `الأذن للاستماع إلى الأخبار أو البودكاست الأجنبية،`}
						</span>{' '}
						{language === 'English'
							? `and they'll help you understand and follow along.`
							: `وستساعدك على الفهم والمتابعة.`}
					</span>{' '}
					<span className='d6'>
						{language === 'English' ? `With` : `مع`}{' '}
						<span className='lightgray-class'>
							99 {language === 'English' ? `earbuds` : `سماعة`}
						</span>
						{language === 'English'
							? `, you'll be able to connect with your loved ones no matter the language barrier.`
							: `ستتمكن من التواصل مع أحبائك بدون أن يقف حاجز اللغة بينكم.`}
					</span>
				</span>
			),
		},
	]

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.main1',
					start: 'top center',
					end: '+=300',
					scrub: 1.5,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d1', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d1',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.main2',
					start: 'top center',
					end: '+=200',
					scrub: 1.2,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d2', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d2',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.main3',
					start: 'top center',
					end: '+=250',
					scrub: 1.2,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d3', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d3',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.main4',
					start: 'top center',
					end: '+=150',
					scrub: 1.2,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d4', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d4',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.main5',
					start: 'top center',
					end: '+=250',
					scrub: 1.2,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d5', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d5',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	useEffect(() => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: '.main6',
					start: 'top center',
					end: '+=250',
					scrub: 1.2,
					toggleActions: 'play none none reverse',
				},
			})
			.to('.d6', {
				color: 'white',
				opacity: 1,
				duration: 20,
				stagger: 5,
			})
			.to(
				'.d6',
				{
					color: '#6e6e73',
					opacity: 0.85,
					duration: 20,
					stagger: 5,
				},
				'=-10'
			)
	}, [])

	return (
		<section id={id} className='contentSection'>
			<div className='contentSection-container'>
				{contentArray.map(({ key, head, para }) => (
					<HeadingTextSection
						language={language}
						key={key}
						h1Heading={head}
						h5Heading={para}
					/>
				))}
			</div>
		</section>
	)
}

export default Index
